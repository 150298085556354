import { graphql } from "gatsby";
import * as React from "react"
import { useTranslation } from "react-i18next"
import DefaultLayout from "../layout";

import * as styles from "./index.module.scss"

import image from "../images/jametlene-reskp-HJljK-svzBQ-unsplash.jpg"

const IndexPage = () => {
  const {t} = useTranslation();

  return (
    <DefaultLayout title={t("hometitle")} topbarTransparent={true}>
      <div className={styles.hero} style={{backgroundImage: "url("+image+")"}}>
        <div className={styles.heroOverlay}>
          <div className={styles.layoutBox}>
            <div className={styles.heroBlurBox}>
              <h1>{t("underConstruction")}</h1>
              <p>{t("underConstructionText")}</p>
            </div>
          </div>
        </div>
      </div>
    </DefaultLayout>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;